import {useGeneralApp} from "~/stores/generalApp.js";
import {useUserStore} from "~/stores/user.js";
import {navigateTo} from "#app";
import {useUpdateUserShortPlaces} from "~/composables/general/updateUserShortPlaces.js";

export const useHandleChangeAppearance = async () => {
    try {
        const generalApp = useGeneralApp()
        const userStore = useUserStore()
        await useUpdateUserShortPlaces()
        generalApp.customPlacesList = true
        generalApp.setActivePlaces = generalApp.tempPlaces
        navigateTo(`/user/${userStore.testUser.id}`)
    } catch (err) {
        console.log('err in change appearance', err)
    }
}