import {UseGetData} from "~/composables/getData.js";
import {UseUpdateData} from "~/composables/updateData.js";
import {useGeneralApp} from "~/stores/generalApp.js";
import {useUserStore} from "~/stores/user.js";

export const useUpdateUserShortPlaces = async () => {
    try {
        const generalApp = useGeneralApp()
        const userStore = useUserStore()
        let currentState = await UseGetData('user','simplified_places')
        if (currentState !== generalApp.setShortPlace) {
            await UseUpdateData('user', 'simplified_place', generalApp.setShortPlace)
            userStore.testUser.simplified_places = await UseGetData('user','simplified_places')
        }
    } catch (err) {
        console.log(err, 'error in update user short places')
    }
}